@media (width <= 160em) {
  body {
    font-size: 1.8rem;
  }
}

@media (width <= 80em) {
  .article h2 {
    letter-spacing: .2rem;
    font-size: 2.8rem;
    line-height: 1.2;
  }

  .article p {
    padding: 2.1rem 0;
  }

  .page-read .section-footer {
    height: 6rem;
  }

  .page-read .section-footer a {
    font-size: 1.7rem !important;
  }

  .blog-container .article {
    gap: 0;
  }

  .blog-container .article .article-title {
    font-size: 2.8rem;
    line-height: 1.2;
  }

  .section-blog .topic-title {
    font-size: 4.8rem;
  }

  .blog-img-container .main-title, .section-story .main-title {
    padding-top: 10%;
    font-size: 5.4rem;
  }

  .partition .secondary-title {
    font-size: 4.8rem !important;
  }
}

@media (width <= 68em) {
  body {
    font-size: 1.6rem;
  }

  .section-nav {
    grid-template-columns: 1fr 1fr;
    height: 7rem;
  }

  .container-logo:link, .container-logo:visited, .container-logo:hover, .container-logo:active {
    height: 7rem;
    font-size: medium;
  }

  .list-nav {
    font-size: medium;
  }

  .list-el--footer {
    grid-template-columns: 1fr 1fr;
    height: 8rem;
  }

  .form {
    gap: 2.4rem;
    width: 90%;
    padding: 2.4rem 0;
  }

  .form div {
    width: 90%;
  }

  .form--textarea {
    height: 8rem;
  }

  .form--quote {
    width: 90% !important;
  }

  .section-blog .main-img {
    height: 25rem;
  }

  .section-blog .topic-title {
    font-size: 4rem;
  }

  .article h2 {
    letter-spacing: .2rem;
    font-size: 2.8rem;
    line-height: 1.2;
  }

  .article p {
    padding: 1.2rem 0;
  }

  .page-read .section-footer {
    height: 6rem;
  }

  .page-read .section-footer a {
    font-size: 1.6rem !important;
  }

  .blog-container .article .article-title {
    font-size: 2.4rem;
    line-height: 1.2;
  }

  .section-articles .article {
    padding-top: 40%;
  }

  .section-story {
    grid-template-columns: 4fr 1fr;
  }

  .section-story .opening-letter {
    font-size: 5.4rem;
  }

  .blog-img-container .main-title, .section-story .main-title {
    padding-top: 15%;
    padding-left: 5%;
    font-size: 4.8rem;
  }

  .partition .secondary-title {
    font-size: 4.2rem !important;
  }

  .section-story .tertiary-title {
    font-size: 2.8rem;
  }

  .section-story .secondary-title {
    font-size: 3.2rem;
  }

  .blog-img-container-box {
    height: 30%;
  }

  .blog-img-container-box-end {
    height: 15%;
  }

  table {
    display: flex;
    overflow-x: scroll;
    width: 100% !important;
  }

  .section-story .story-quotes {
    gap: 2rem;
  }
}

@media (width <= 37.5em) {
  .section-nav {
    height: 6rem;
  }

  .container-logo:link, .container-logo:visited, .container-logo:hover, .container-logo:active {
    white-space: nowrap;
    letter-spacing: .1px;
    height: 6rem;
    font-size: 1.5rem;
  }

  .container-logo .logo-img {
    height: 70%;
  }

  .list-nav {
    gap: 10%;
    font-size: small;
  }

  h2 {
    font-size: 1.8rem;
  }

  .list-el--footer {
    grid-template-columns: 1fr;
    gap: 2.4rem;
    height: fit-content;
    padding: 5%;
  }

  .submit-btn {
    border-radius: 2.4rem;
    height: 4rem;
    font-size: 1.6rem;
  }

  .form div {
    gap: .4rem;
  }

  .icon--close-form {
    font-size: 1.9rem;
  }

  .frankel--link-container {
    border-top: .01px solid #fafafa;
    padding: .8rem 0;
  }

  .frankel--link {
    font-size: 1.8rem;
  }

  .guitar--img {
    height: 6rem;
  }

  .section-articles .articles {
    gap: 1.8rem;
    display: grid;
    grid-template-columns: 1fr !important;
  }

  .section-articles .article {
    padding-top: 5%;
  }

  .article h2 {
    letter-spacing: .15rem;
    font-size: 2.4rem;
  }

  .article p {
    padding: 1.2rem 0;
  }

  .page-read .section-footer {
    height: 6rem;
  }

  .page-read .section-footer a {
    font-size: 1.6rem !important;
  }

  .blog-container .article .article-title {
    font-size: 2.4rem;
    line-height: 1.2;
  }

  .section-blog .blog-container {
    grid-template-columns: 1fr;
    gap: 3.2rem;
  }

  .section-story {
    flex-direction: column;
    gap: 0;
    display: flex;
  }

  .section-story .opening-letter {
    font-size: 5.4rem;
  }

  .blog-img-container .main-title, .section-story .main-title {
    padding-top: 15%;
    padding-left: 15%;
    font-size: 3.2rem;
  }

  .partition .secondary-title {
    line-height: 1.2;
    font-size: 3.2rem !important;
  }

  .section-story .tertiary-title {
    font-size: 2.4rem;
  }

  .section-story .secondary-title {
    font-size: 2.8rem;
  }

  .blog-img-container-box {
    height: 25%;
  }

  .blog-img-container-box-end {
    height: 13%;
  }

  .section-story .partition {
    grid-template-columns: 1fr;
    column-gap: 1rem;
    padding: 1.2rem 0;
    display: grid;
  }

  .section-blog .topic-title {
    letter-spacing: .3rem;
    color: #a1000b;
    font-size: 2.4rem;
  }

  .section-story {
    height: fit-content;
  }

  .section-story .story-quotes {
    flex-direction: column;
    gap: 1.2rem;
    height: fit-content;
    padding: 10% 0 12%;
    display: flex;
    width: 100% !important;
  }
}
/*# sourceMappingURL=home.13383cfb.css.map */
