/* includes blog media  and read2 media */

/**************************/
/* BELOW 1920×1080 */
/**************************/
@media (max-width: 160em) {
  body {
    font-size: 1.8rem;
  }
}

/**************************/
/* BELOW 1536×864 */
/**************************/
@media (max-width: 96em) {
}

/**************************/
/* BELOW 1366×768 */
/**************************/
@media (max-width: 85.375em) {
}

/**************************/
/* BELOW 1280×720*/
/**************************/
@media (max-width: 80em) {
  .article h2 {
    font-size: 2.8rem;
    letter-spacing: 0.2rem;
    line-height: 1.2;
  }
  .article p {
    padding: 2.1rem 0;
  }

  .page-read .section-footer {
    height: 6rem;
  }

  .page-read .section-footer a {
    font-size: 1.7rem !important;
  }

  .blog-container .article {
    gap: 0rem;
  }

  .blog-container .article .article-title {
    /* like in read2.css line 94 */
    font-size: 2.8rem;
    line-height: 1.2;
  }

  .section-blog .topic-title {
    font-size: 4.8rem;
  }

  /* the actual blog */
  .blog-img-container .main-title,
  .section-story .main-title {
    padding-top: 10%;
    font-size: 5.4rem;
  }

  .partition .secondary-title {
    font-size: 4.8rem !important;
  }
}

/**************************/
/* BELOW 763×1024*/
/**************************/
@media (max-width: 68em) {
  body {
    font-size: 1.6rem;
  }

  .section-nav {
    grid-template-columns: 1fr 1fr;
    height: 7rem;
  }

  .container-logo:link,
  .container-logo:visited,
  .container-logo:hover,
  .container-logo:active {
    height: 7rem;
    font-size: medium;
  }

  .list-nav {
    font-size: medium;
  }

  .list-el--footer {
    grid-template-columns: 1fr 1fr;
    height: 8rem;
  }

  .form {
    width: 90%;
    padding: 2.4rem 0;
    gap: 2.4rem;
  }

  .form div {
    width: 90%;
  }

  .form--textarea {
    height: 8rem;
  }

  .form--quote {
    width: 90% !important;
  }

  .section-blog .main-img {
    height: 25rem;
  }

  .section-blog .topic-title {
    font-size: 4rem;
  }

  .article h2 {
    font-size: 2.8rem;
    letter-spacing: 0.2rem;
    line-height: 1.2;
  }
  .article p {
    padding: 1.2rem 0;
  }

  .page-read .section-footer {
    height: 6rem;
  }

  .page-read .section-footer a {
    font-size: 1.6rem !important;
  }

  .blog-container .article .article-title {
    /* like in read2.css line 94 */
    font-size: 2.4rem;
    line-height: 1.2;
  }

  .section-articles .article {
    padding-top: 40%;
  }

  /* the actual blog */
  .section-story {
    grid-template-columns: 4fr 1fr;
  }

  .section-story .opening-letter {
    font-size: 5.4rem;
  }

  .blog-img-container .main-title,
  .section-story .main-title {
    padding-top: 15%;
    font-size: 4.8rem;
    padding-left: 5%;
    /* width: 50%; */
  }

  .partition .secondary-title {
    font-size: 4.2rem !important;
  }

  .section-story .tertiary-title {
    font-size: 2.8rem;
  }

  .section-story .secondary-title {
    font-size: 3.2rem;
  }

  .blog-img-container-box {
    height: 30%;
  }

  .blog-img-container-box-end {
    height: 15%;
  }

  table {
    display: flex;
    width: 100% !important;
    overflow-x: scroll;
  }

  .section-story .story-quotes {
    /* padding: 0% 1% 40% 1%; */
    gap: 2rem;
  }
}

/**************************/
/* BELOW 412×915*/
/**************************/
@media (max-width: 37.5em) {
  .section-nav {
    height: 6rem;
  }

  .container-logo:link,
  .container-logo:visited,
  .container-logo:hover,
  .container-logo:active {
    height: 6rem;
    font-size: 1.5rem;
    white-space: nowrap;
    letter-spacing: 0.1;
  }

  .container-logo .logo-img {
    height: 70%;
  }

  .list-nav {
    font-size: small;
    gap: 10%;
  }

  h2 {
    font-size: 1.8rem;
  }

  .list-el--footer {
    grid-template-columns: 1fr;
    height: fit-content;
    gap: 2.4rem;
    padding: 5%;
  }

  .submit-btn {
    height: 4rem;
    font-size: 1.6rem;
    border-radius: 2.4rem;
  }

  .form div {
    gap: 0.4rem;
  }

  .icon--close-form {
    font-size: 1.9rem;
  }

  .frankel--link-container {
    border-top: 0.01 solid #fafafa;
    padding: 0.8rem 0;
  }

  .frankel--link {
    font-size: 1.8rem;
  }

  .guitar--img {
    height: 6rem;
  }

  .section-articles .articles {
    display: grid;
    grid-template-columns: 1fr !important;
    gap: 1.8rem;
  }

  .section-articles .article {
    padding-top: 5%;
  }

  .article h2 {
    font-size: 2.4rem;
    letter-spacing: 0.15rem;
  }
  .article p {
    padding: 1.2rem 0;
  }

  .page-read .section-footer {
    height: 6rem;
  }

  .page-read .section-footer a {
    font-size: 1.6rem !important;
  }

  .blog-container .article .article-title {
    /* like in read2.css line 94 */
    font-size: 2.4rem;
    line-height: 1.2;
  }

  .section-blog .blog-container {
    grid-template-columns: 1fr;
    gap: 3.2rem;
  }

  /* the actual blog */
  .section-story {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .section-story .opening-letter {
    font-size: 5.4rem;
  }

  .blog-img-container .main-title,
  .section-story .main-title {
    padding-top: 15%;
    font-size: 3.2rem;
    padding-left: 15%;

    /* width: 50%; */
  }

  .partition .secondary-title {
    font-size: 3.2rem !important;
    line-height: 1.2;
  }

  .section-story .tertiary-title {
    font-size: 2.4rem;
  }

  .section-story .secondary-title {
    font-size: 2.8rem;
  }

  .blog-img-container-box {
    height: 25%;
  }

  .blog-img-container-box-end {
    height: 13%;
  }

  .section-story .partition {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1.2rem 0;
    column-gap: 1rem;
  }

  .section-blog .topic-title {
    font-size: 2.4rem;
    letter-spacing: 0.3rem;
    color: #a1000b;
  }

  .section-story {
    height: fit-content;
  }

  .section-story .story-quotes {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    height: fit-content;
    padding: 10% 0 12% 0;
  }
}

/**************************/
/* BELOW 360×800*/
/**************************/
@media (max-width: 22.5em) {
}

/***********************************/
/* BELOW 390 width and 700 height*/
/***********************************/
@media (max-width: 24.375em), (max-height: 43.75em) {
}
